<template>
  <div class="container pr">
    <van-cell
      v-for="(item, index) in cells"
      :key="index"
      :title="item.title"
      is-link
      @click="handleContractInfo(item)"
    />
    <p class="hotline pa u-text-center">客服热线： 400-100-1188</p>
  </div>
</template>

<script>
export default {
  name: "Contract",
  data() {
    return {
      cells: [
        // {
        //   title: "《借款确认书》",
        //   type: 0,
        // },
        // {
        //   title: "《非在校大学生承诺书》",
        //   type: 1,
        // },
        {
          title: "《借款合同》",
          type: 2,
        },
        // {
        //   title: "《越风委托担保合同》",
        //   type: 3,
        // },
        // {
        //   title: "《中裔担保及补充担保合同》",
        //   type: 4,
        // },
        // {
        //   title: "《评估咨询服务协议》",
        //   type: 5,
        // },
        // {
        //   title: "《众安委托扣款授权书》",
        //   type: 6,
        // },
      ],
    };
  },
  methods: {
    handleContractInfo(item) {
      this.$router.push({
        name: "ContractInfo",
        params: item,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  background-color: #f3f3f3;
  height: 100vh;
  overflow: auto;
}

.van-cell {
  padding: 0.26rem 0.15rem 0.26rem 0.3rem;
  background: none;
}

.van-cell::after {
  right: 0;
  border-bottom: 0.02rem solid #c8c7cc;
}

.van-cell__right-icon {
  color: #c7c7c7;
  font-size: 0.4rem;
}

.hotline {
  width: 100%;
  font-size: 0.27rem;
  left: 0;
  bottom: 0.35rem;
  color: #aaa;
}
</style>
